//
// Dropdown
// --------------------------------------------------


// Caret

.dropdown, .dropup,
.dropleft, .dropright {
  .dropdown-toggle::after {
    margin-left: .3rem;
    vertical-align: middle;
  }
}
.btn.dropdown-toggle::after {
  margin: {
    bottom: .125rem;
    left: .35rem;
  }
  vertical-align: baseline;
}


// Dropdown menu

.dropdown-menu {
  @include box-shadow($dropdown-box-shadow);
  @if $enable-shadows {
    border-color: $dropdown-bg;
  }
  li:hover > .dropdown-item {
    color: $dropdown-link-hover-color;
  }
  .active > .dropdown-item {
    color: $dropdown-link-active-color;
  }
}


// Dropdown header

.dropdown-header {
  margin-bottom: .25rem;
  padding: {
    top: $dropdown-item-padding-y;
    bottom: $dropdown-item-padding-y * 2;
  }
  border-bottom: $dropdown-border-width solid $dropdown-border-color;
  font-weight: $headings-font-weight;
}


// Dropdown divider

.dropdown-divider { margin: .25rem 0; }


// Dropdown item

.dropdown-item {
  position: relative;
  width: 100%;
  transition: $dropdown-link-transition;
  font: {
    size: $dropdown-item-font-size;
    weight: $nav-link-font-weight;
  }
  > i, .badge { vertical-align: middle; }
}


// Dropdown menu animation

.dropdown-menu.show {
  animation: fade-in .25s ease-in-out;
}


// Split button dropdown fix

.dropdown-toggle-split::after {
  margin-left: -.0625rem !important;
}


// Dropdown component animations

@keyframes fade-in  {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes slide-up {
  from {
    transform: translateY(.5rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

//
// Gallery
// based on https://github.com/sachinchoolur/lightgallery.js
// ----------------------------------------------------------


.gallery-item {
  display: block;
  position: relative;
  text-decoration: none !important;
  overflow: hidden;

  > img {
    display: block;
    width: 100%;
  }

  // overlay
  &::before {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: opacity .3s ease-in-out;
    background-color: $gallery-overlay-bg;
    content: '';
    opacity: 0;
    z-index: 1;
  }

  // indicator
  &::after {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $gallery-indicator-size;
    height: $gallery-indicator-size;
    margin: {
      top: -($gallery-indicator-size / 2);
      left: -($gallery-indicator-size / 2);
    }
    transform: scale(.3);
    color: $gallery-image-indicator-color;
    font: {
      family: 'cartzilla-icons';
      size: $gallery-image-indicator-font-size;
    }
    text-align: center;
    line-height: $gallery-indicator-size;
    content: '\e900';
    z-index: 5;
  }

  // item caption
  .gallery-item-caption {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: $spacer ($spacer / 2);
    transform: translateY(.5rem);
    color: $gallery-caption-color;
    font-size: $gallery-caption-font-size;
    text-align: center;
    z-index: 5;
  }

  &::after,
  .gallery-item-caption {
    transition: all .3s ease-in-out;
    opacity: 0;
  }
  
  // hover state
  &:hover {
    &::before, &::after,
    .gallery-item-caption {
      transform: none;
      opacity: 1;
    }
  }

  // video item
  &.video-item::after {
    transform: none;
    border-radius: 50%;
    background-color: $gallery-video-indicator-bg;
    color: $gallery-video-indicator-color;
    font: {
      size: $gallery-video-indicator-font-size;
      weight: 700;
    }
    opacity: 1;
    box-shadow: $gallery-video-indicator-box-shadow;
    content: '\e969';
  }
}
.lg-on { overflow: hidden; }

//
// Countdown Timer
// --------------------------------------------------

.cz-countdown {
  display: flex;
  flex-wrap: wrap;
  font-weight: normal;

  .cz-countdown-value {
    font-weight: $countdown-value-font-weight;
  }
  .cz-countdown-label {
    margin-left: .125rem;
    font-size: $countdown-label-font-size;
  }
  .cz-countdown-days,
  .cz-countdown-hours,
  .cz-countdown-minutes,
  .cz-countdown-seconds {
    margin: {
      right: .75rem;
      bottom: .75rem;
    }
  }
  .cz-countdown-seconds { margin-right: 0; }
}
